import { Form, Select, Input, ConfigProvider, Empty, Typography } from "antd";
import styles from "./EmailDrawerForm.module.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useEmailRecipients } from "shared/hooks/adLibrary/marketingMaterials";

export const EmailDeliveryForm = ({ id }: { id: string }) => {
  const { data: defaultRecipients } = useEmailRecipients();
  return (
    <>
      <Form.Item
        className={styles["form-item"]}
        label={`From`}
        name={[id, "from"]}
        labelAlign={`left`}
        rules={[
          {
            required: true,
            message: `This field is required.`,
          },
        ]}
      >
        <Select disabled={true} mode="multiple" />
      </Form.Item>
      <Form.Item
        className={styles["form-item"]}
        label={<div>To:</div>}
        colon={false}
        tooltip={{
          title: "You can enter or paste multiple email addresses",
          icon: <InfoCircleOutlined />,
        }}
        name={[id, "to"]}
        labelAlign={`left`}
        rules={[
          {
            required: true,
            message: `This field is required.`,
          },
          {
            validator: (_, value: string[]) => {
              if (!value || value.length === 0) {
                return Promise.resolve();
              }
              const invalidEmails = value.filter(
                email => !/^\S+@\S+\.\S+$/.test(email),
              );
              if (invalidEmails.length > 0) {
                return Promise.reject(
                  new Error(
                    "One or more of the addresses entered are invalid.",
                  ),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div className={styles["empty-state-description"]}>
                  <Typography.Text className={styles.title}>
                    No emails found
                  </Typography.Text>
                  <Typography.Text>
                    You can enter or paste multiple email addresses{" "}
                  </Typography.Text>
                </div>
              }
            />
          )}
        >
          <Select
            options={defaultRecipients?.map(v => ({ value: v, label: v }))}
            tokenSeparators={[",", " "]}
            mode="tags"
            placeholder="Please select or add new email"
            showArrow
          />
        </ConfigProvider>
      </Form.Item>
      <Form.Item
        className={styles["form-item"]}
        labelAlign="left"
        label={`Subject`}
        name={[id, `subject`]}
        rules={[
          {
            required: true,
            message: `This field is required.`,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};
