import {
  AppstoreOutlined,
  BookOutlined,
  BuildOutlined,
  DollarOutlined,
  FileImageOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  FormOutlined,
  HomeOutlined,
  LineChartOutlined,
  NotificationOutlined,
  PartitionOutlined,
  PicLeftOutlined,
  PictureOutlined,
  PrinterOutlined,
  TableOutlined,
  TeamOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { CAMConfig } from "shared/components/media";
import { AflacAccountIcon } from "shared/icons/Aflac";
import { AiIcon } from "shared/icons/AiIcon";
import { IMenu } from "shared/types/menu";
import { AlexiaModule, AlexiaSubModule } from "shared/types/shared";
import {
  brandsAccountsEnabled,
  isEnvVarEquals,
  isFeatureEnabled,
  isRawEnvVarEquals,
} from "utils/helpers";
import { designStudioTabs } from "utils/helpers.designStudio";

const adReviewEnabled = isFeatureEnabled("ENABLE_AD_REVIEW", true);
const pictorEnabled = isFeatureEnabled("ENABLE_DIEM", true);
const assetExporterEnabled = isFeatureEnabled("ENABLE_ASSET_EXPORTER", true);
const assetBuilderEnabled = isFeatureEnabled("ENABLE_ASSET_BUILDER", true);
const legalLingoEnabled = isFeatureEnabled("ENABLE_LEGAL_LINGO", true);
const legalLingoV2Enabled = isFeatureEnabled("ENABLE_LEGAL_LINGO_V2", false);
const dashboardEnabled = isFeatureEnabled("ENABLE_LOOKER_DASHBOARDS", false);
const platformManagementDisabled =
  isEnvVarEquals("ENABLE_PLATFORM_MANAGEMENT", "false") ||
  isEnvVarEquals("ENABLE_PLATFORM_MANAGEMENT", "");
const audienceManagerEnabled = isFeatureEnabled(
  "ENABLE_AUDIENCE_MANAGER",
  false,
);
const designStudioV2Enabled = isFeatureEnabled("ENABLE_DESIGN_STUDIO_V2");
const campaignPlannerEnabled = isFeatureEnabled(
  "ENABLE_CAMPAIGN_PLANNER",
  false,
);

const pmaxAssetGroupsEnabled = isFeatureEnabled("ENABLE_PMAX", false);
const pmaxCampaignsEnabled =
  pmaxAssetGroupsEnabled && isFeatureEnabled("ENABLE_PMAX_CAMPAIGNS", false);
const aiImageGenEnabled = isFeatureEnabled("ENABLE_AI_IMAGE_GENERATION", false);
const isAdLoadV2Enabled = isFeatureEnabled("ENABLE_AD_LOAD_V2");
const isLad = isEnvVarEquals("CLIENT", "ladtech");
const isAflac = isEnvVarEquals("CLIENT", "aflac");
const isBeeswaxEnabled = isFeatureEnabled("ENABLE_BEESWAX");

const isPrd = isRawEnvVarEquals("REACT_APP_ENV", "prd");

const designStudioSubMenus = designStudioV2Enabled
  ? undefined
  : [
      {
        header: "Templates",
        path: "/design-studio/library/templates",
        subModule: AlexiaSubModule.TEMPLATES,
        hidden: !designStudioTabs.templatesEnabled,
      },
      {
        header: "Stamps",
        path: "/design-studio/library/stamps",
        subModule: AlexiaSubModule.STAMPS,
        hidden: !designStudioTabs.stampsEnabled,
      },
      {
        header: "Artboards",
        path: "/design-studio/library/artboards",
        subModule: AlexiaSubModule.ARTBOARDS,
        hidden: !designStudioTabs.artboardsEnabled,
      },
    ];

export const salesEnablementMenu: IMenu[] = [
  {
    header: "Home",
    Icon: HomeOutlined,
    module: AlexiaModule.HOME,
    path: "/sales-enablement/home",
  },
  {
    header: "Agents",
    Icon: TeamOutlined,
    module: AlexiaModule.AGENTS,
    path: "/sales-enablement/agents",
  },
  {
    header: "Accounts",
    Icon: AflacAccountIcon,
    module: AlexiaModule.ACCOUNTS,
    path: "/sales-enablement/accounts",
  },
  {
    header: "Products",
    Icon: BookOutlined,
    module: AlexiaModule.PRODUCTS,
    path: "/sales-enablement/products",
  },
  {
    header: "Disclosures",
    Icon: FormOutlined,
    module: AlexiaModule.DISCLOSURES,
    path: "/sales-enablement/disclosures",
  },
  {
    header: "Templates",
    Icon: FileImageOutlined,
    module: AlexiaModule.TEMPLATES,
    path: "/sales-enablement/templates",
  },
  {
    header: "My Marketing Materials",
    Icon: NotificationOutlined,
    module: AlexiaModule.MY_MARKETING_MATERIALS,
    path: "/sales-enablement/my-marketing-materials",
  },
  {
    header: "Archive",
    Icon: FolderOpenOutlined,
    module: AlexiaModule.ARCHIVE,
    path: "/sales-enablement/archive",
  },
  {
    header: "Print Options",
    Icon: PrinterOutlined,
    module: AlexiaModule.PRINT_OPTIONS,
    path: "/sales-enablement/print-options",
    hidden: isPrd,
  },
  {
    header: "Invoices",
    Icon: DollarOutlined,
    module: AlexiaModule.INVOICES,
    path: "/sales-enablement/invoices",
    hidden: process.env.REACT_APP_ENV === "prd",
  },
  {
    header: "Print Archive",
    Icon: PrinterOutlined,
    module: AlexiaModule.PRINT_ARCHIVE,
    path: "/sales-enablement/print-archive",
    hidden: isPrd,
  },
  {
    header: "Metrics",
    Icon: LineChartOutlined,
    module: AlexiaModule.METRIC,
    subMenus: [
      {
        header: "Overview",
        path: "/sales-enablement/metrics/overview",
        subModule: AlexiaSubModule.OVERVIEW,
      },
      {
        header: "Delivered Materials",
        path: "/sales-enablement/metrics/delivered-materials",
        subModule: AlexiaSubModule.DELIVERED_MATERIALS,
      },
      {
        header: "Undelivered Materials",
        path: "/sales-enablement/metrics/undelivered-materials",
        subModule: AlexiaSubModule.UNDELIVERED_MATERIALS,
      },
      {
        header: "Search Frequency",
        path: "/sales-enablement/metrics/search-frequency",
        subModule: AlexiaSubModule.SEARCH_FREQUENCY,
        hidden: process.env.REACT_APP_ENV === "prd",
      },
      {
        header: "Support Data",
        path: "/sales-enablement/metrics/support-data",
        subModule: AlexiaSubModule.SUPPORT_DATA,
        hidden: process.env.REACT_APP_ENV === "prd",
      },
      {
        header: "OSAT",
        path: "/sales-enablement/metrics/osat",
        subModule: AlexiaSubModule.OSAT,
        hidden: process.env.REACT_APP_ENV === "prd",
      },
      {
        header: "Network Engagement",
        path: "/sales-enablement/metrics/network-engagement",
        subModule: AlexiaSubModule.NETWORK_ENGAGEMENT,
      },
      {
        header: "Template Usage",
        path: "/sales-enablement/metrics/template-usage",
        subModule: AlexiaSubModule.TEMPLATE_USAGE,
      },
    ],
  },
];

const brochuresMenu: IMenu[] = [
  {
    header: "Brochure Repository",
    Icon: PicLeftOutlined,
    module: AlexiaModule.BROCHURE_MANAGEMENT,
    path: "/brochure-management",
  },
];

const createMenus = (): IMenu[] => {
  if (isAflac) {
    const menus: IMenu[] = [];
    if (isFeatureEnabled("ENABLE_SALES_ENABLEMENT"))
      menus.push(...salesEnablementMenu);
    if (isFeatureEnabled("ENABLE_BROCHURE_MANAGEMENT"))
      menus.push(...brochuresMenu);
    return menus;
  }
  return [
    {
      header: "Admin",
      Icon: AppstoreOutlined,
      module: AlexiaModule.ADMIN,
      subMenus: [
        // {
        //   header: "User Management",
        //   path: "/user-management",
        //   subModule: AlexiaSubModule.USER_MANAGEMENT,
        //   hidden: false,
        // },
        ...(isLad
          ? [
              {
                header: "Store Management",
                path: "/store-management",
                subModule: AlexiaSubModule.STORE_MANAGEMENT,
                hidden: false,
              },

              {
                header: "OEM Management",
                path: "/oem-management",
                subModule: AlexiaSubModule.OEM_MANAGEMENT,
                hidden: false,
              },
            ]
          : []),
        {
          header: "Dashboard Management",
          path: "/dashboard-management",
          subModule: AlexiaSubModule.DASHBOARD_MANAGEMENT,
          hidden: !dashboardEnabled,
        },
        {
          header: "Brands and Accounts",
          path: "/brands-accounts-management",
          subModule: AlexiaSubModule.BRANDS_MANAGEMENT,
          hidden: !brandsAccountsEnabled,
        },
        {
          header: "Platform Management",
          path: "/platform-management",
          subModule: AlexiaSubModule.PLATFORM_MANAGEMENT,
          hidden: platformManagementDisabled,
        },
      ],
    },
    {
      header: "Legal Lingo",
      module: AlexiaModule.LEGAL_LINGO,
      Icon: FormOutlined,
      hidden: !legalLingoEnabled,
      subMenus: [
        {
          header: "Variables",
          path: "/legal-lingo/variables",
          subModule: AlexiaSubModule.VARIABLES,
          hidden: false,
        },
        {
          header: "Disclosures",
          path: "/legal-lingo/disclosures",
          subModule: AlexiaSubModule.DISCLOSURES,
          hidden: false,
        },
        {
          header: "Messaging",
          path: "/legal-lingo/messaging",
          subModule: AlexiaSubModule.MESSAGING,
          hidden: false,
        },
      ],
    },
    {
      header: "Legal Lingo V2",
      module: AlexiaModule.LEGAL_LINGO,
      Icon: FormOutlined,
      path: "/legal-lingo-v2",
      hidden: !legalLingoV2Enabled,
    },
    {
      header: "Asset Builder",
      module: AlexiaModule.ASSET_BUILDER,
      Icon: BuildOutlined,
      path: "/asset-builder/orders",
      hidden: !assetBuilderEnabled,
    },
    {
      header: isLad ? "Custom Feed Module" : "Ad Engine",
      module: AlexiaModule.ASSET_EXPORTER,
      Icon: ThunderboltOutlined,
      path: "/ad-engine",
      hidden: !assetExporterEnabled,
    },
    {
      header: "Asset Export",
      module: AlexiaModule.ASSET_EXPORT,
      Icon: TableOutlined,
      path: "/asset-export",
      hidden: true,
    },
    {
      header: "Design Studio",
      module: AlexiaModule.DESIGN_STUDIO,
      Icon: PictureOutlined,
      path: "/design-studio",
      hidden: Object.values(designStudioTabs).every(tab => !tab),
      subMenus: designStudioSubMenus,
    },
    // the DesignStudio component must be mounted, so need to add hidden menu
    {
      header: "",
      module: AlexiaModule.DESIGN_STUDIO,
      Icon: PictureOutlined,
      path: "/design-studio",
      hidden: true,
    },
    {
      header: "Ad Library",
      module: AlexiaModule.AD_LIBRARY,
      Icon: TableOutlined,
      subMenus: [
        {
          header: "Ads",
          path: "/ad-library",
          subModule: AlexiaSubModule.ADS,
          hidden: false,
        },
        {
          header: "Ad Load",
          path: "/ad-library/load",
          subModule: AlexiaSubModule.AD_LOAD,
          hidden: true,
        },
        {
          header: "Sessions",
          path: "/ad-library/sessions",
          subModule: AlexiaSubModule.SESSION,
          hidden: isAdLoadV2Enabled,
        },
        {
          header: "Google Performance Max",
          path: "/ad-library/pmax-asset-groups",
          subModule: AlexiaSubModule.PMAX_ASSET_GROUPS,
          hidden: !pmaxAssetGroupsEnabled,
        },
      ],
    },
    // Routes everything-ads create/edit routes to DesignStudio component
    {
      header: "",
      module: AlexiaModule.INSTANT_EXPERIENCE,
      Icon: PictureOutlined,
      path: "/everything-ads/ads/*",
      hidden: true,
    },
    {
      header: "Everything Ads",
      module: AlexiaModule.INSTANT_EXPERIENCE,
      Icon: PartitionOutlined,
      hidden: !pictorEnabled,
      subMenus: [
        {
          header: "Ads",
          path: "/everything-ads/ads",
          subModule: AlexiaSubModule.EVERYTHING_ADS,
          hidden: !designStudioTabs.instantExperienceEnabled,
        },
        {
          header: "Mapper",
          path: "/everything-ads/mapper",
          subModule: AlexiaSubModule.EVERYTHING_ADS_MAPPER,
        },
      ],
    },
    {
      header: "Campaign Management",
      module: AlexiaModule.CAMPAIGN_MANAGEMENT,
      Icon: FolderOpenOutlined,
      hidden: !adReviewEnabled && !audienceManagerEnabled,
      path: "/campaign-management/*",
      subMenus: [
        {
          header: "Ad Review",
          path: "/campaign-management/ad-review",
          subModule: AlexiaSubModule.AD_REVIEW,
          hidden: !adReviewEnabled,
        },
        {
          header: "Audience Manager",
          path: "/campaign-management/audience-manager",
          subModule: AlexiaSubModule.AUDIENCE_MANAGER,
          hidden: !audienceManagerEnabled,
        },
        {
          header: "Campaign Planners",
          subModule: AlexiaSubModule.CAMPAIGN_PLANNER,
          path: "/campaign-management/campaign-planner",
          hidden: !campaignPlannerEnabled,
          isPublic: false,
        },
        {
          header: "Google Performance Max",
          path: "/campaign-management/google-performance-max",
          subModule: AlexiaSubModule.CAMPAIGNS_PMAX,
          hidden: !pmaxCampaignsEnabled,
          isPublic: false,
        },
        {
          header: "Beeswax",
          path: "/campaign-management/beeswax",
          subModule: AlexiaSubModule.BEESWAX,
          hidden: !isBeeswaxEnabled,
        },
      ],
    },
    {
      header: "Metrics",
      module: AlexiaModule.METRICS,
      Icon: LineChartOutlined,
      hidden: !dashboardEnabled,
      subMenus: [
        {
          header: "Dashboards",
          path: "/metrics",
          subModule: AlexiaSubModule.DASHBOARDS,
          hidden: false,
        },
        {
          header: "Dashboards",
          path: "/metrics/share/:id",
          subModule: AlexiaSubModule.DASHBOARDS,
          hidden: true,
        },
        {
          header: "Shared Links to Dashboard",
          path: "/metrics/shared-links",
          subModule: AlexiaSubModule.SHARED_DASHBOARD_MANAGEMENT,
          hidden: false,
        },
      ],
    },
    {
      header: "Creative Asset Manager",
      module: AlexiaModule.MEDIA,
      Icon: FolderOutlined,
      path: "/media",
      hidden: !CAMConfig.active,
    },
    {
      header: "AI Tools",
      module: AlexiaModule.AI_IMAGE_GEN,
      Icon: AiIcon,
      path: "/ai-image-tools/generate/",
      hidden: !aiImageGenEnabled,
    },
  ];
};

export const configMenus = createMenus();
